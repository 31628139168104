import React from "react"
import { L10nProvider } from '@logdoc/l10n';
import { Page404 } from '../404'


const NotFound = () => {
    return (
        <L10nProvider>
            <Page404 />
        </L10nProvider>
    )
}

export default NotFound
