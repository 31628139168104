import React from "react"
import * as classes from '../sass/style.module.scss'
import { Link } from "gatsby"
import clearPathname from '../utils/link'
import { L10nProvider } from '@logdoc/l10n';

const NotFound = () => {
    return (
        <L10nProvider>
            <Page404 />
        </L10nProvider>
    )
}

export const Page404 = () => {
  return (
      <main className={classes.notFound}>
          <section className={classes.notFoundItem}>
              <div className="container">
                  <div className="sec-404_inner">
                      <div className="sec-404_img">
                          <img src="/img/404.png" alt={404} />
                      </div>
                      <div className="sec-404_description">
                          К сожалению такой страницы не существует, вы можете вернуться <br />
                          на <Link style={{ color: '#E35B28' }} to={clearPathname("/")}> <span className={classes.textOrange}>главную страницу</span></Link>
                      </div>
                  </div>
              </div>
          </section>
          {/* /404 */}
      </main>
  )
}

export default NotFound
